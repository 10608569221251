import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deviceType: null,
    networkSpeed: null,
    operatingSystem: null,
    browser: null,
};

const MobileEnvironmentSlice = createSlice({
    name: 'mobileEnvironment',
    initialState: initialState,
    reducers: {
        setDeviceType: (state, action) => {
            state.deviceType = action.payload;
        },
        setNetworkSpeed: (state, action) => {
            state.networkSpeed = action.payload;
        },
        setOperatingSystem: (state, action) => {
            state.operatingSystem = action.payload;
        },
        setBrowser: (state, action) => {
            state.browser = action.payload;
        },
    },
});

export const {
    setDeviceType,
    setNetworkSpeed,
    setOperatingSystem,
    setBrowser,
} = MobileEnvironmentSlice.actions;

export default MobileEnvironmentSlice.reducer;
