import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    schoolPreviews: [],
    selectedSchool: null,
    winnerSlice: [],
    schoolStats: [],
    activeSchool: [],

})


const schoolSlice = createSlice({
    name: 'schoolSlice',
    initialState: initialState(),
    reducers: {
        resetSchools: state => initialState(),
        setSchoolPreviews: (state, action) => {
            state.schoolPreviews = action.payload
        },
        setSelectedSchool: (state, action) => {
            state.selectedSchool = action.payload
        },
        setWinnerSlice: (state, action) => {
            state.winnerSlice = action.payload
        },
        setSchoolStats: (state, action) => {
            state.schoolStats = action.payload
        },
        setActiveSchool: (state, action) => {
            state.activeSchool = action.payload
        }
    }
})

export const { resetSchools, setSchoolPreviews, setSelectedSchool, setWinnerSlice, setSchoolStats, setActiveSchool } = schoolSlice.actions

export default schoolSlice.reducer