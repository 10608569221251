import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './schemas/authSlice';
import helperFunctionsSlice from './schemas/helperFunctionsSlice';
import campaignSlice from './schemas/campaignSlice';
import paymentSlice from './schemas/paymentSlice';
import schoolSlice from './schemas/schoolSlice';

export const createReduxStore = (preloadedState) => {
  return configureStore({
    reducer: {
      auth:authSlice,
      mainapp: combineReducers({
        helperFunctions: helperFunctionsSlice,
        campaignSlice: campaignSlice,
        schoolSlice:schoolSlice,
        paymentSlice:paymentSlice

      
      })
    },
    preloadedState
  });
};

// For client-side: 
