import { createAsyncThunk } from '@reduxjs/toolkit'
import { isLogin, isNotLogin, resetAuth, userInformation } from '../store/schemas/authSlice'
import { notifications } from '@mantine/notifications';
import { alterNavigate, alterThunkLoading } from '../store/schemas/helperFunctionsSlice';
import { server } from '../helpers/serverDetection';


export const getUser = createAsyncThunk(
    'auth/getUser',
    async (data, thunkApi) => {
        try {
            const response = await fetch(`${server}/api?auth=true&model=user&path=/getUser`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({})
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(isLogin())
            thunkApi.dispatch(userInformation(results.message))
            return results
        } catch (error) {

            return error
        }
    }
)


export const signUpWithCode = createAsyncThunk(
    'auth/signUpWithCode',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))

            const response = await fetch(`${server}/api?auth=false&model=auth&path=/register`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ schoolUid: data.schoolUid, phone: data.phone, password: data.password, code: data.code, firstName: data.firstName, lastName: data.lastName })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(login({ phone: data.phone, password: data.password }))
            return results
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return error
        }
    }
)

export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async (data, thunkApi) => {
        try {
            if (!window.location.pathname.includes('/app/onboarding/verification')) {
                thunkApi.dispatch(alterThunkLoading({ visible: true }))
            }
            const response = await fetch(`${server}/api?auth=false&model=auth&path=/generateAndSendCode`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ phone:data.phone, email: data.email, type: "passwordReset", admin: data.admin })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
           


            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return results
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return error
        }
    }
)

export const verifyPhone = createAsyncThunk(
    'auth/verifyPhone',
    async (data, thunkApi) => {
        try {
            if (!window.location.pathname.includes('/app/onboarding/verification')) {
                thunkApi.dispatch(alterThunkLoading({ visible: true }))
            }
            const response = await fetch(`${server}/api?auth=false&model=auth&path=/generateAndSendCode`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ phone: data.phone, type: "verification" })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(alterThunkLoading({ visible: false }))



            // return results
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return error
        }
    }
)

export const validateCode = createAsyncThunk(
    'auth/validateCode',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))


            if (data.type == 'reset') {
                const response = await fetch(`${server}/api?auth=false&model=auth&path=/verifyCodeAndPerformAction`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({phone:data.phone, email:data.email, code: data.code, type: "verifyCode", admin: data.admin })
                })
                const results = await response.json()
                if ("error" in results) {
                    throw results
                }

                thunkApi.dispatch(alterThunkLoading({ visible: false }))

                return results
            }
            else if (data.type == 'passwordReset') {
                const response = await fetch(`${server}/api?auth=false&model=auth&path=/verifyCodeAndPerformAction`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ phone:data.phone, email:data.email, newPassword: data.newPassword, code: data.code, type: "passwordReset", admin: data.admin })
                })
                const results = await response.json()
                if ("error" in results) {
                    throw results
                }
                    await thunkApi.dispatch(getUser())
                    thunkApi.dispatch(alterThunkLoading({ visible: false }))
                    if(data.admin == true){
                        thunkApi.dispatch(alterNavigate({ url: "/adminPanel/dashboard" }))
                    }
                    else{
                        thunkApi.dispatch(alterNavigate({ url: "/app" }))
                    }
                
                return results
            }
            else {
                const response = await fetch(`${server}/api?auth=false&model=auth&path=/verifyCodeAndPerformAction`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ phone:data.phone, email:data.email, password:data.password, code: data.code, type: "verification", admin: data.admin})
                })
                const results = await response.json()
                if ("error" in results) {
                    throw results
                }

                if(data.password != null){
                    await thunkApi.dispatch(getUser())
                    thunkApi.dispatch(alterThunkLoading({ visible: false }))
                    if(data.admin == true){
                        thunkApi.dispatch(alterNavigate({ url: "/adminPanel/dashboard" }))
                    }
                    else{
                        thunkApi.dispatch(alterNavigate({ url: "/app" }))
                    }
                }
               
                return results

            }



        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return error
        }
    }
)


export const login = createAsyncThunk(
    'auth/login',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))
            const response = await fetch(`${server}/api?auth=false&model=auth&path=/login`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email: data.email, phone:data.phone, password: data.password, admin: data.admin })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }

     
            thunkApi.dispatch(alterThunkLoading({ visible: false }))
            /* COMMENT ME OUT TO DISABLE VERIFY SKIP ON PHONE */

            if(process.env.NODE_COMPILE == 'split'){
                setTimeout(() => {
                    if(data.admin == true){
                        window.location.href = '/adminPanel/dashboard'
                    }
                    else{
                        window.location.href = '/app'
                    }
                }, 1000)
               
                
                throw {error: "Dev Bypass 2fa", message: "Redirecting to app"}
            }

            return results
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            return error
        }
    }
)


export const logout = createAsyncThunk(
    'auth/logout',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))
            const response = await fetch(`${server}/api?auth=false&model=auth&path=/signOut`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({})
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            thunkApi.dispatch(resetAuth())
            localStorage.clear()
            window.location.href = '/app/onboarding'

            return results
        } catch (error) {
            notifications.show({ title: error.error, message: error.message })
            return error
        }
    }
)

export const requestRemovalOfData = createAsyncThunk(
    'auth/requestRemovalOfData',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))
            const response = await fetch(`${server}/api?auth=true&model=user&path=/requestRemovalOfData`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({})
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }
            await thunkApi.dispatch(logout())

            return results
        } catch (error) {
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            notifications.show({ title: error.error, message: error.message })
            return error
        }
    }
)





export const requestNoSellOfDataPublic = createAsyncThunk(
    'auth/requestNoSellOfDataPublic',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))
            const response = await fetch(`${server}/api?auth=false&model=userPublic&path=/requestNoSellOfData`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ first_name: data.first_name, last_name: data.last_name, email: data.email, state: data.state })
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }

            // @Jacoby make naviagation / actions here
            alert('Your request has been submitted. Thank you.')
            thunkApi.dispatch(alterThunkLoading({ visible: false }))
            window.location.reload()
            return results
        } catch (error) {
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            notifications.show({ title: error.error, message: error.message })
            return error
        }
    }
)

export const requestNoSellOfDataStudent = createAsyncThunk(
    'auth/requestNoSellOfDataStudent',
    async (data, thunkApi) => {
        try {
            thunkApi.dispatch(alterThunkLoading({ visible: true }))
            const response = await fetch(`${server}/api?auth=true&model=user&path=/requestNoSellOfData`, {
                method: 'POST',
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({})
            })
            const results = await response.json()
            if ("error" in results) {
                throw results
            }

            // @Jacoby make naviagation / actions here
            thunkApi.dispatch(alterThunkLoading({ visible: false }))
            alert('Your request has been submitted. Thank you.')
            window.location.reload()

            return results
        } catch (error) {
            thunkApi.dispatch(alterThunkLoading({ visible: false }))

            notifications.show({ title: error.error, message: error.message })
            return error
        }
    }
)