import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    nav:null,
    thunkLoading:{
        visible:false,
        text:''
    },
    backButton: null
})


const helperFunctionsSlice = createSlice({
    name:'helperFunctionsSlice',
    initialState:initialState(),
    reducers: {
        resetHelperFunction: state => initialState(),
        alterNavigate:(state, action)=>{   
            state.nav = {url:action.payload.url, replace: ("replace" in action.payload ? true : false)}
        },
        alterBackNavigate: (state,action) => {
            state.backButton = action.payload
        },
        alterThunkLoading:(state, action)=>{   
            state.thunkLoading = {visible:action.payload.visible}
        }
    }
})

export const {resetHelperFunction,alterBackNavigate , alterNavigate, alterThunkLoading} = helperFunctionsSlice.actions

export default helperFunctionsSlice.reducer