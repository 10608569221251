import { createSlice } from "@reduxjs/toolkit";

const initialState = () => ({
    currentPayments: null,
    currentStats: {
        total: 0,
    },
  

})


const paymentSlice = createSlice({
    name:'Payment Slice',
    initialState:initialState(),
    reducers: {
        resetPayments: state => initialState(),
        setCurrentPayments: (state, action) => {
            state.currentPayments = action.payload
        }
    }
})

export const {resetPayments, setCurrentPayments} = paymentSlice.actions

export default paymentSlice.reducer